import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import 'rxjs/add/operator/map'
@Injectable({
  providedIn: 'root'
})
export class EventsHasProcedimientosService {


  constructor(private httpClient: HttpClient) { }
  ////////////////////Procedimientos data
  getProcedimientobyEventID(evento_id:number) {
    return this.httpClient
    .get(environment.api.events_procedimientos_byevent_id_get+"/"+evento_id
    ).map(data =>
    data);
  }
  getEventsProcedimientosByID(evento_proc_id:number) {
    return this.httpClient
    .get(environment.api.events_procedimientos_data_by_id_get+"/"+evento_proc_id
    ).map(data =>
    data);
  }
  saveProcedimientosData(procedimiento) {
    return this.httpClient
    .post(environment.api.events_procedimientos_byevent_id_post, procedimiento
    ).map(data =>
    data);
  }
  deleteProcedimientosData(evento_proc_id:number) {
    return this.httpClient
    .delete(environment.api.events_procedimientos_byevent_id_delete+"/"+evento_proc_id
    ).map(data =>
    data);
  }
  }


