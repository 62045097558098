import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import 'rxjs/add/operator/map'

import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators/switchMap';
import { finalize } from 'rxjs/operators/finalize';
import { timeout } from 'rxjs/operators/timeout';
import { catchError } from 'rxjs/operators/catchError';
import { _throw } from 'rxjs/observable/throw';
import { retry } from 'rxjs/operators/retry';
import { of } from 'rxjs/observable/of';
@Injectable({
  providedIn: 'root'
})
export class DcmFilesSService {

  constructor(private httpClient: HttpClient) { }
  deleteFile(data) {
    return this.httpClient
    .post(environment.api.dcm_files_delete,data
    ).map(data =>
    data);
  }
  getFile(data) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.httpClient
    .post(environment.api.dcm_files_get, data,httpOptions
    ).map(data =>
    data);
  }

 async  getMultipleFile(data) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    /*return new  Promise ((resolve)=>{
      fetch(environment.api.dcm_files_get, {method:"POST", body:JSON.stringify(data)}).then((response)=>response.blob()).then((blob)=>{
        resolve(blob)
      })
    });*/
    return await this.httpClient
    .post(environment.api.dcm_files_get, data,httpOptions
    ).toPromise().catch((err: HttpErrorResponse)=>{
      return err.error;
    });
  }

  _newFileDeprecate(data):Observable<HttpEvent<any>> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Cache-Control': 'no-cache'
         });
    const req = new HttpRequest('POST',environment.api.dcm_files_new, data,
    {
      headers:httpHeaders,
      reportProgress: true,
      responseType: 'json',
    });
    return this.httpClient.request(req);
  }
  newFile(data){
    return this.httpClient
    .post(environment.api.dcm_files_new, data
    ).map(data =>
    data);

   /* let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Cache-Control': 'no-cache'
         });
    const req = new HttpRequest('POST',environment.api.dcm_files_new, data,
    {
      headers:httpHeaders,
      reportProgress: true,
      responseType: 'json',
    });
    return this.httpClient.request(req);*/
  }

  saveFilesData(data) {
    return this.httpClient
    .post(environment.api.dcm_files_data_new, data
    ).map(data =>
    data);
  }
  getFilesDataByEventAndPatientID(event_id, patient_id) {
    return this.httpClient
    .get(environment.api.dcm_files_data_get+"/"+event_id+"/"+patient_id
    ).map(data =>
    data);
  }
  getFilesDataByFilters(event_id, patient_id, specialist_id, init_year, end_year) {
    return this.httpClient
    .get(environment.api.dcm_files_data_filters_get+"/"+event_id+"/"+patient_id+"/"+specialist_id+"/"+init_year+"/"+end_year
    ).map(data =>
    data);
  }



}
