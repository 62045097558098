import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment.dev";
@Injectable({
  providedIn: "root",
})
export class EventsOutcomesService {
  constructor(private httpClient: HttpClient) {}
  getEventsOutcomes_by_patientId(paciente_id) {
    return this.httpClient
      .get(environment.api.events_outcomes_by_patient_id + "/" + paciente_id)
      .map((data) => data);
  }
  getEventsOutcomes_by_EventId(evento_id) {
    return this.httpClient
      .get(environment.api.events_outcomes_by_event_id + "/" + evento_id)
      .map((data) => data);
  }
  getEventsOutcomesID(outcomes_id) {
    return this.httpClient
      .get(
        environment.api.events_outcomes_by_patient_id +
          "/" +
          outcomes_id
      )
      .map((data) => data);
  }
  newEventsOutcomes(outcomes) {
    return this.httpClient
      .post(environment.api.events_outcomes_new, outcomes)
      .map((data) => data);
  }
}
