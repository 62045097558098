import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import 'rxjs/add/operator/map'


@Injectable({
  providedIn: 'root'
})
export class ContentsService {

  constructor(private httpClient: HttpClient) { }


  getContentsByID(id:number) {
    return this.httpClient
    .get(environment.api.contents_get+"/"+id
    ).map(data =>
    data);
  }

}
