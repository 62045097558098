import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import 'rxjs/add/operator/map'
@Injectable({
  providedIn: 'root'
})
export class MedicalInformationService {

  constructor(private httpClient: HttpClient) { }
  ////////////////////ANTHROPOMETRIC DATA
  getAnthropometricByPatientID(patient_id:number) {
    return this.httpClient
    .get(environment.api.get_anthropometric_data_by_patient_id+"/"+patient_id
    ).map(data =>
    data);
  }
  getAnthropometricByID(anthropometric_id:number) {
    return this.httpClient
    .get(environment.api.get_anthropometric_data_by_id+"/"+anthropometric_id
    ).map(data =>
    data);
  }
  newAnthropometricData(anthropometric) {
    return this.httpClient
    .post(environment.api.new_anthropometric_data, anthropometric
    ).map(data =>
    data);
  }
  /////////////////////
  deleteNoPathologicalByPatientID(patient_history_id:number) {
    return this.httpClient
    .delete(environment.api.delete_no_pathological_elements+"/"+patient_history_id
    ).map(data =>
    data);
  }
  deletePathologicalByPatientID(patient_history_id:number) {
    return this.httpClient
    .delete(environment.api.delete_pathological_elements+"/"+patient_history_id
    ).map(data =>
    data);
  }
  getPathologicalByPatientAndEventID(patient_id:number, event_id:number) {
    return this.httpClient
    .get(environment.api.get_pathological_elements+"/"+patient_id+"/"+event_id
    ).map(data =>
    data);
  }
  newPathologicalElement(pathological_element) {
    return this.httpClient
    .post(environment.api.new_pathological_elements, pathological_element
    ).map(data =>
    data);
  }

  deleteNOPathologicalByPatientID(patient_history_id:number) {
    return this.httpClient
    .delete(environment.api.delete_no_pathological_elements+"/"+patient_history_id
    ).map(data =>
    data);
  }
  getNOPathologicalByPatientID(patient_id:number) {
    return this.httpClient
    .get(environment.api.no_pathological_elements_by_patient_id+"/"+patient_id
    ).map(data =>
    data);
  }
  getNOPathologicalByPatientAndEVentID(patient_id:number, event_id:number) {
    return this.httpClient
    .get(environment.api.no_pathological_elements_by_patient_id_and_event_id+"/"+patient_id+"/"+event_id
    ).map(data =>
    data);
  }
  newNOPathologicalElement(no_pathological_element) {
    return this.httpClient
    .post(environment.api.new_no_pathological_elements, no_pathological_element
    ).map(data =>
    data);
  }
}
