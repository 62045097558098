import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import 'rxjs/add/operator/map'

@Injectable({
  providedIn: 'root'
})
export class ClinicalResultsService {

  constructor(private httpClient: HttpClient) { }


  getClinicalResultsByPatientEventID(patient_id:number, event_id:number) {
    return this.httpClient
    .get(environment.api.clinical_results_get+"/"+patient_id+"/"+event_id
    ).map(data =>
    data);
  }

  saveClinicalResults(clinical_results_data) {
    return this.httpClient
    .post(environment.api.clinical_results_save, clinical_results_data
    ).map(data =>
    data);
  }
}
